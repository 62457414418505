import { Button, Card, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { AnalyticsDataType } from '../../types/User';
import { ContentDialogBox } from '../../components/dialog_box';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


type Props = {}

function Home({ }: Props) {
    const [data, setData] = React.useState<AnalyticsDataType[]>([]);
    const [totalStatistics, setTotalStatistics] = React.useState<{
        totalUsers: number,
        totalInteractions: number,

    }>({
        totalUsers: 0,
        totalInteractions: 0
    });
    const [currentStatistics, setCurrentStatistics] = React.useState<{
        todaysNewUsers: number,
        todaysNewInteractions: number,
    }>({
        todaysNewUsers: 0,
        todaysNewInteractions: 0
    });
    const [currentData, setCurrentData] = React.useState<AnalyticsDataType>();
    const [isSpecificUserOpen, setIsSpecificUserOpen] = React.useState(false);


    async function getAnalyticsData() {
        try {
            const response = await fetch("https://backend.educobot.com/mobileApp/GetAllTrackingDataForUsersAPI", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.status === 200) {
                const result = await response.json() as {
                    data: AnalyticsDataType[]
                };
                setData(result.data)
                const date = new Date().toLocaleDateString("en-GB")
                const tempData = result.data
                const totalUsers = tempData.length
                let todayInteractions = 0
                let totalInteractions = 0
                tempData.forEach((value) => {
                    console.log(value);
                    
                    if (!!value.trackingContent ) {
                        value.trackingContent[date]&& (todayInteractions += Object.keys(value.trackingContent[date]).length)
                        Object.keys(value.trackingContent).forEach((key) => {
                            totalInteractions += Object.keys(value.trackingContent[key]).length
                        })
                    }
                })
                let todaysNewUsers = 0
                tempData.forEach((value) => {
                    if (!!value.trackingContent) {


                        if (value.trackingContent[date] && Object.keys(value.trackingContent).length === 1) {
                            todaysNewUsers++
                        }
                    }
                })
                setTotalStatistics({
                    totalUsers,
                    totalInteractions
                })
                setCurrentStatistics({
                    todaysNewInteractions: todayInteractions,
                    todaysNewUsers
                })
            }
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        getAnalyticsData();
    }, [])
    return (
        <div>
            <Container>
                <Typography variant='h6' sx={{
                    textDecoration: "underline"
                }}>
                    Current Day Statistics
                </Typography>
                <Typography sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0",
                    gap: "10px",
                    marginBottom: "20px"
                }}>
                    <Card style={{
                        width: "200px",
                        padding: "10px",
                    }}>
                        <h4>New Users Count</h4>
                        <h1>
                            {
                                currentStatistics.todaysNewUsers
                            }
                        </h1>
                    </Card>
                    <Card style={{
                        width: "200px",
                        padding: "10px",
                    }}>
                        <h4>New Interactions Count</h4>
                        <h1>
                            {
                                currentStatistics.todaysNewInteractions
                            }
                        </h1>
                    </Card>

                </Typography>
                <Typography variant='h6' sx={{
                    textDecoration: "underline"
                }}>
                    Total Statistics
                </Typography>
                <Typography sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0",
                    gap: "10px",
                    marginBottom: "20px"
                }}>
                    <Card style={{
                        width: "200px",
                        padding: "10px",
                    }}>
                        <h4>Total Users Count</h4>
                        <h1>
                            {
                                totalStatistics.totalUsers
                            }
                        </h1>
                    </Card>
                    <Card style={{
                        width: "200px",
                        padding: "10px",
                    }}>
                        <h4>Total Interactions Count</h4>
                        <h1>
                            {
                                totalStatistics.totalInteractions
                            }
                        </h1>
                    </Card>

                </Typography>
                <Typography variant='h6' sx={{
                    textDecoration: "underline"
                }}>
                    SAAS Users
                </Typography>

                <Typography style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    {
                        data && data.map((item, index) => {
                            if (!!item.trackingContent)
                                return (
                                    <Card key={index} style={{
                                        width: "100%",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                        <div>
                                            <h4>{item.userDetails.ssFName} {item.userDetails.ssLName}</h4>
                                            <p style={{
                                                fontSize: "12px",
                                            }}>{item.userDetails.ssEmailID}</p>
                                        </div>
                                        <Button size='small' variant='outlined' onClick={() => {
                                            setIsSpecificUserOpen(true)
                                            setCurrentData(item)
                                        }} style={{
                                            textTransform: "none",
                                        }}>
                                            View
                                        </Button>
                                    </Card>
                                )
                        })
                    }
                </Typography>
            </Container>

            {
                isSpecificUserOpen && <ContentDialogBox
                    isOpen={isSpecificUserOpen}
                    onClose={() => {
                        setIsSpecificUserOpen(false)
                    }}
                    title={currentData?.userDetails.ssFName} content={<>

                        <UserLogTimeLine user={currentData as AnalyticsDataType} />
                    </>}
                />
            }

        </div>
    )
}

export default Home





export function UserLogTimeLine({ user }: {
    user: AnalyticsDataType
}) {



    useEffect(() => {
        console.log(user);

    }, [])
    if (!user) return <></>
    return (
        <>
            <div style={{
                // background: f",
            }}>
                <VerticalTimeline layout='1-column' lineColor='yellow' animate={true} >
                    {
                        user.trackingContent && Object.keys(user.trackingContent).reverse().map((key, index) => {
                            const item = user.trackingContent[key]
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                        // date={key}
                                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    >
                                        <h3 className="vertical-timeline-element-title">{key}</h3>
                                        <h6 className="vertical-timeline-element-subtitle">Total {Object.keys(item).length} Interactions</h6>
                                        <VerticalTimeline layout='2-columns'>
                                            {
                                                Object.keys(item).reverse().map((key, index) => {
                                                    const interaction = item[key]
                                                    return (
                                                        <>

                                                            <VerticalTimelineElement
                                                                className="vertical-timeline-element--work"
                                                                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                                                // date={key}
                                                                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

                                                            >
                                                                <h3 className="vertical-timeline-element-title">{key}</h3>
                                                                <h4 className="vertical-timeline-element-subtitle">{interaction.status}</h4>
                                                            </VerticalTimelineElement>
                                                        </>
                                                    )
                                                })
                                            }


                                        </VerticalTimeline>
                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                    }

                </VerticalTimeline>



            </div>

        </>
    )
}