import React from 'react'
import Home from './Home'
import Header from '../../components/templates/Header'

const HomeLayout: React.FC = () => {
    return (
        <div >
            <Header/>
            <Home />
        </div>
    )
}

export default HomeLayout