import { Button, Container } from '@mui/material'
import React from 'react'

type Props = {}

function Header({ }: Props) {
  return (
    <div>
      <Container sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <h1 style={{
          padding: "10px 0",
        }}>
          <img src="https://educobot.com/imgs/eduCOBOTSingleLine.svg" style={{
            width: "150px",
          }} alt="" />
        </h1>
        <Button variant='outlined' color='secondary' style={{
          textTransform:"none"
        }}>App Analytics</Button>
      </Container>
    </div>
  )
}

export default Header